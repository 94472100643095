import React from "react";
import {Accordion, Alert, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {vmcService} from "../Xhr/vmc.service";
import Loading from "./Loading";

interface Props {
    sellerId: number,
    buyerId: number,
    onUncouple: () => void
}

interface State {
    loading: boolean,
    error: string
}

class ChainLink extends React.Component<Props, State> {

    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            error: ''
        }
        this._isMounted = false;
        this.breakChain = this.breakChain.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    breakChain() {
        if (this._isMounted) {
            this.setState({loading: true});
            vmcService.uncouple({
                seller: this.props.sellerId,
                buyer: this.props.buyerId,
            })
                .then(() => {
                    if (this._isMounted) {
                        this.props.onUncouple();
                        this.setState({loading: false});
                    }
                })
                .catch(e => {
                    if (this._isMounted) {
                        this.setState({loading: false, error: e.data.message})
                    }
                })
        }
    }

    render() {
        return (
            <Accordion>
                <div className="col-auto h-100 mb-3">
                    <Accordion.Toggle as={Button} variant={'light'} block eventKey={'confirmBreak'} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={faLink} size={'2x'}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={'confirmBreak'}>
                        <>
                            <p className={'mb-3 mt-2'}>Are you sure you want to break this link?</p>
                            <Alert variant={'danger'} style={{opacity: 1}} show={this.state.error !== ''}>{this.state.error}</Alert>
                            <Button variant="primary" onClick={this.breakChain} size={'lg'} className={'mx-1'} disabled={this.state.loading} style={{minWidth: '85px'}}>
                                {this.state.loading ? <Loading className={'text-light'}/> : 'Break Link'}
                            </Button>
                        </>
                    </Accordion.Collapse>
                </div>
            </Accordion>
        )
    }

}

export default ChainLink;
