import React from "react";
import moment, {Moment} from "moment/moment";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Milestone, MilestoneType} from "../vmc-types";
import {DatePicker} from "@material-ui/pickers";
import {vmcService} from "../Xhr/vmc.service";
import {Accordion, Alert, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import Loading from "./Loading";

interface Props {
    milestoneType: MilestoneType,
    listingId: number,
    milestone: Milestone | undefined,
    onMilestoneChange: () => void,
    onMilestoneError: (error: string) => void
}

interface State {
    date: Moment | null,
    loading: boolean,
    error: string,
    activeKey: string
}

class MilestoneEditor extends React.Component<Props, State> {

    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            error: "",
            loading: false,
            date: props.milestone && props.milestone.attributes.completed_at ? moment.unix(props.milestone.attributes.completed_at) : null,
            activeKey: ''
        }
        this._isMounted = false;
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleMilestoneDelete = this.handleMilestoneDelete.bind(this);
        this.handleConfirmToggle = this.handleConfirmToggle.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleDateChange(date: Moment | MaterialUiPickersDate) {
        if (this._isMounted) {
            this.setState({date});
            if (date) {
                vmcService.updateMilestone(this.props.listingId, this.props.milestoneType.id, date.format('YYYY-MM-DD'))
                    .then(() => this.props.onMilestoneChange())
                    .catch(e => {
                        if (e.data.errors && e.data.errors.hasOwnProperty(this.props.milestoneType.attributes.key) && Array.isArray(e.data.errors[this.props.milestoneType.attributes.key])) {
                            this.props.onMilestoneError(e.data.errors[this.props.milestoneType.attributes.key][0]);
                        } else {
                            this.props.onMilestoneError(e.data.message);
                        }
                    })
            }
        }
    }

    handleMilestoneDelete() {
        if (this.props.milestone) {
            if (this._isMounted) {
                this.setState({loading: true, error: ''});
            }
            vmcService.deleteMilestone(this.props.listingId, this.props.milestone.id)
                .then(() => {
                    if (this._isMounted) {
                        this.setState({loading: false, error: '', date: null, activeKey: ''});
                    }
                    this.props.onMilestoneChange();
                })
                .catch(e => {
                    this.props.onMilestoneError(e.data.message);
                });
        }
    }

    handleConfirmToggle() {
        if (this._isMounted) {
            this.setState({activeKey: this.state.activeKey === 'confirm' ? '' : 'confirm'});
        }
    }

    render() {
        return (
            <Accordion activeKey={this.state.activeKey}>
                <div className={'row'}>
                    <div className="col">
                        <DatePicker value={this.state.date} onChange={this.handleDateChange}
                                    format="DD/MM/yyyy" autoOk label={this.props.milestoneType.attributes.name}
                                    fullWidth
                                    clearable/>
                    </div>
                    <div className="col-auto d-flex flex-column justify-content-center align-items-center p-0">
                        {this.state.date &&
                        <Accordion.Toggle as={Button} variant={'link'} eventKey={'confirm'} className={'p-0'} onClick={this.handleConfirmToggle}>
                            <FontAwesomeIcon icon={faMinusCircle} className={'text-red'}/>
                        </Accordion.Toggle>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Accordion.Collapse eventKey={'confirm'}>
                            <Alert variant={'warning'} style={{opacity: 1}} className={'mt-3 mb-0'}>
                                <p className={'mb-2'}>Are you sure you want to remove this milestone?</p>
                                <Button variant={'danger'} size={'lg'} disabled={this.state.loading} onClick={this.handleMilestoneDelete}>{this.state.loading ?
                                    <Loading className={'text-light'}/> : 'Delete Milestone'}</Button>
                            </Alert>
                        </Accordion.Collapse>
                    </div>
                </div>
            </Accordion>
        );
    }


}

export default MilestoneEditor;
