import React from 'react';
import PropTypes from 'prop-types';
import {Listing, MilestoneGroup, MilestoneGroupState} from "../vmc-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import moment from 'moment';
import styles from "./MilestoneGroupBlock.module.scss";

interface IProps {
    listing: Listing,
    group: MilestoneGroup,
    state: MilestoneGroupState | any,
    onClick: (group: MilestoneGroup) => void
}

class MilestoneGroupBlock extends React.Component<IProps> {
    static propTypes: {};
    static defaultProps: {};

    constructor(props: IProps) {
        super(props);

        this.state = {};

    }

    render() {
        let bg = '';
        let groupState = {
            state: 'none',
            key_milestone: 0,
            context: null,
            requires_action: false,
            requires_confirmation: false,
            is_locked: false,
            actionable_milestone_type: null,
            label: ''
        };
        if (this.props.state) {
            groupState = this.props.state;
        }
        switch (groupState.state) {
            case 'fulfilled':
                bg = styles.success;
                break;
            case 'warning':
                bg = styles.warning;
                break;
            case 'none':
            default:
                bg = 'bg-white';
        }
        if(groupState.is_locked){
            bg = styles.info;
        }
        return (
            <button className={bg + ' p-2 d-flex flex-column justify-content-between h-100 shadow w-100 text-center ' + styles.block}
                    onClick={() => this.props.onClick(this.props.group)}>
                <p className={'mb-0 w-100'}>{groupState.label || this.props.group.attributes.name}
                    {groupState.requires_action && <>&nbsp;<FontAwesomeIcon icon={faExclamationTriangle}/></>}
                    {!groupState.requires_action && groupState.requires_confirmation && <>&nbsp;<FontAwesomeIcon icon={faQuestionCircle}/></>}
                    {!groupState.requires_action && !groupState.requires_confirmation && groupState.is_locked && <>&nbsp;<FontAwesomeIcon icon={faLock}/></>}
                </p>
                {!!groupState.key_milestone && <p className={'mb-0 w-100'}>{moment.unix(groupState.key_milestone).format('D MMM Y')}</p>}
            </button>
        );
    }
}

MilestoneGroupBlock.propTypes = {
    listing: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    state: PropTypes.object
};

MilestoneGroupBlock.defaultProps = {
    state: {
        state: 'none',
        key_milestone: 0,
        context: null,
        requires_action: false,
        requires_confirmation: false,
        is_locked: false,
        actionable_milestone_type: null,
        label: ''
    }
};

export default MilestoneGroupBlock;
