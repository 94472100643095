import React from 'react';
import ReziApi from "../Xhr/ReziApi";
import {Context} from '../DataContracts/Context';
import Search from "./Search";
import {vmcService} from "../Xhr/vmc.service";
import {Listing} from "../vmc-types";
import "./app.scss";
import {Property} from "../rezi-types";
import Loading from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-regular-svg-icons/faHandshake";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons/faPoundSign";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faCheckSquare} from "@fortawesome/free-regular-svg-icons/faCheckSquare";
import ChainProgressBar from "./ChainProgressBar";
import styles from "./Chain.module.scss";
import {Button} from "react-bootstrap";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import ChainBuilder from "./ChainBuilder";
import Notes from "./Notes";

interface IProps {
    rezi: ReziApi
    context: Context
}

interface State {
    property: Property | null,
    listingId: number,
    loading: boolean,
    listings: Listing[]
}

class Chain extends React.Component<IProps, State> {
    private _isMounted: boolean;

    constructor(props: any) {
        super(props);

        this.state = {
            property: null,
            listingId: 0,
            loading: false,
            listings: []
        };
        this.loadListing = this.loadListing.bind(this);
        this.resetListingId = this.resetListingId.bind(this);
        this.reloadListing = this.reloadListing.bind(this);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadProperty();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<State>, snapshot?: any) {
        if (!prevProps.context.PropertySalesRoleDetails && this.props.context.PropertySalesRoleDetails) {
            this.loadProperty();
        }
    }

    loadProperty() {
        if (this.props.context.PropertySalesRoleDetails && this._isMounted) {
            this.setState({loading: true});
            this.props.rezi.GetProperty(this.props.context.PropertySalesRoleDetails.PropertyId)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({property: response.data, loading: false});
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadListing(id: number) {
        if (this._isMounted) {
            this.setState({loading: true, listingId: id});
        }
        vmcService.chainedListings(id)
            .then(response => {
                if (this._isMounted) {
                    this.setState({listings: response.data.data, loading: false});
                }
            });
    }

    reloadListing() {
        if (this._isMounted) {
            this.setState({loading: true});
        }
        vmcService.chainedListings(this.state.listingId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({listings: response.data.data, loading: false});
                }
            });
    }

    resetListingId() {
        if (this._isMounted) {
            this.setState({listingId: 0});
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }
        if (!this.state.listingId && this.state.property) {
            return <Search address={this.state.property.Address} onSelect={this.loadListing}/>
        }
        const rows = this.state.listings.map(listing => <ChainProgressBar listing={listing} key={listing.id}/>);
        return (
            <div className={'row justify-content-center flex-wrap'}>
                <div className="col-md-12 text-left">
                    <div className="row">
                        <div className="col px-0">
                            <Button variant={'link'} onClick={this.resetListingId}><FontAwesomeIcon icon={faChevronCircleLeft}/>&nbsp;Back</Button>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <ChainBuilder listings={this.state.listings} listingId={this.state.listingId} onChainChange={this.reloadListing}/>
                </div>
                <div className="col-12">
                    <div className={'row d-none d-md-flex px-4 flex-nowrap'}>
                        <div className="col-3 px-2">&nbsp;</div>
                        <div className="col px-1">
                            <div className={'badge w-100 ' + styles.badge}>
                                <FontAwesomeIcon icon={faHandshake}/><p className={'d-none d-md-inline-block mb-0'}>&nbsp;SSTC</p>
                            </div>
                        </div>
                        <div className="col px-1">
                            <div className={'badge w-100 ' + styles.badge}>
                                <FontAwesomeIcon icon={faSearch}/><span className={'d-none d-md-inline-block'}>&nbsp;Searches</span>
                            </div>
                        </div>
                        <div className="col px-1">
                            <div className={'badge w-100 ' + styles.badge}>
                                <FontAwesomeIcon icon={faPoundSign}/><span className={'d-none d-md-inline-block'}>&nbsp;Funding</span>
                            </div>
                        </div>
                        <div className="col px-1">
                            <div className={'badge w-100 ' + styles.badge}>
                                <FontAwesomeIcon icon={faList}/><span className={'d-none d-md-inline-block'}>&nbsp;Enquiries</span>
                            </div>
                        </div>
                        <div className="col px-1">
                            <div className={'badge w-100 ' + styles.badge}>
                                <FontAwesomeIcon icon={faCheckSquare}/><span className={'d-none d-md-inline-block'}>&nbsp;Completion</span>
                            </div>
                        </div>
                    </div>
                    <div className="px-4">
                        {rows}
                    </div>
                </div>
            </div>
        );
    }
}

export default Chain;
