import React from "react";
import {Accordion, Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {ChainDataItem, Listing} from "../vmc-types";
import styles from './ChainBuilder.module.scss';
import {vmcService} from "../Xhr/vmc.service";
import Loading from "./Loading";
import CheckButton from "./CheckButton";
import ChainLink from "./ChainLink";
import ListingAddForm from "./ListingAddForm";

interface IProps {
    listings: Listing[],
    listingId: number
    onChainChange: () => void
}

interface IState {
    chainData: ChainDataItem,
    loading: boolean,
    eventKey: string
}

class ChainBuilder extends React.Component<IProps, IState> {

    private _isMounted: boolean;

    constructor(props: any) {
        super(props);

        this.state = {
            chainData: {
                id: this.props.listingId,
                type: 'chain_data',
                attributes: {
                    top_checked: false,
                    bottom_checked: false,
                    length: 1,
                    position: 1
                }
            },
            loading: true,
            eventKey: ''
        };
        this.toggleTop = this.toggleTop.bind(this);
        this.toggleBottom = this.toggleBottom.bind(this);
        this.handleEventKeyChange = this.handleEventKeyChange.bind(this);
        this.handleChainChangeEvent = this.handleChainChangeEvent.bind(this);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (!!this.props.listingId) {
            this.setState({loading: true});
            vmcService.chainData(this.props.listingId)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({chainData: response.data.data, loading: false});
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleTop(checked: boolean) {
        if (this._isMounted) {
            const chainData = {...this.state.chainData};
            chainData.attributes.top_checked = checked;
            this.setState({chainData});
        }
    }

    toggleBottom(checked: boolean) {
        if (this._isMounted) {
            const chainData = {...this.state.chainData};
            chainData.attributes.bottom_checked = checked;
            this.setState({chainData});
        }
    }

    handleEventKeyChange(eventKey: string) {
        if (this._isMounted) {
            if (eventKey === this.state.eventKey) {
                eventKey = '';
            }
            this.setState({eventKey});
        }
    }

    handleChainChangeEvent() {
        this.props.onChainChange();
        if (this._isMounted) {
            this.setState({eventKey: ''});
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }
        const rows = this.props.listings.map((listing, i) => {
            return <>
                <div className={'col h-100'} key={listing.id}>
                    <Card className={'mb-3 ' + styles.propertyCard}>
                        <Card.Body className={'d-flex align-items-center justify-content-center'}>
                            <FontAwesomeIcon icon={faHome} size={'4x'} className={'my-auto'}/>
                        </Card.Body>
                        <Card.Footer className={'bg-light text-left'}>{listing.attributes.address_street_name}</Card.Footer>
                    </Card>
                </div>
                {i < (this.props.listings.length - 1) &&
                <ChainLink buyerId={listing.id} sellerId={this.props.listings[i + 1].id} onUncouple={() => this.props.onChainChange()} key={listing.id + 'a'}/>
                }
            </>
        });
        return (
            <Accordion activeKey={this.state.eventKey}>
                <div className={'row align-items-center ' + styles.chainBuilder}>
                    <div className={'col h-100'}>
                        <div className={'badge w-100 ' + styles.badge}>Top</div>
                        <CheckButton checked={this.state.chainData.attributes.top_checked} top={true} onToggle={this.toggleTop} listingId={this.props.listingId}/>
                    </div>
                    <div className="col-auto h-100 mb-3">
                        <Accordion.Toggle as={Button} variant={'light'} block eventKey={'top'} onClick={() => this.handleEventKeyChange('top')}>
                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'}/>
                        </Accordion.Toggle>
                    </div>
                    {rows}
                    <div className="col-auto h-100">
                        <Accordion.Toggle as={Button} variant={'light'} block eventKey={'bottom'} onClick={() => this.handleEventKeyChange('bottom')}>
                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'}/>
                        </Accordion.Toggle>
                    </div>
                    <div className={'col h-100'}>
                        <div className={'badge w-100 ' + styles.badge}>Bottom</div>
                        <CheckButton checked={this.state.chainData.attributes.bottom_checked} top={false} onToggle={this.toggleBottom} listingId={this.props.listingId}/>
                    </div>
                </div>
                <Accordion.Collapse eventKey={'top'}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-9 col-lg-6">
                            <ListingAddForm disabled={false} top={true} onListingAdd={this.handleChainChangeEvent} onClose={() => this.handleEventKeyChange('')}
                                            listingId={this.props.listingId}/>
                        </div>
                    </div>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey={'bottom'}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-9 col-lg-6">
                            <ListingAddForm disabled={false} top={false} onListingAdd={this.handleChainChangeEvent} onClose={() => this.handleEventKeyChange('')}
                                            listingId={this.props.listingId}/>
                        </div>
                    </div>
                </Accordion.Collapse>
            </Accordion>
        );
    }
}

export default ChainBuilder;
