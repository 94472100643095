import React, {Component, Requireable} from 'react';
import * as PropTypes from "prop-types";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
    className: string,
    block: boolean,
}

class Loading extends Component<IProps> {
    static propTypes: { className: Requireable<string>; block: Requireable<boolean> };
    static defaultProps: IProps

    render() {
        return <span className={'text-center mb-0 h3' + (this.props.block ? 'd-block' : '')}>
            <FontAwesomeIcon icon={faCircleNotch} spin className={this.props.className}/>
        </span>;
    }
}

Loading.propTypes = {
    className: PropTypes.string,
    block: PropTypes.bool
}

Loading.defaultProps = {
    className: 'text-info',
    block: true
}

export default Loading;
