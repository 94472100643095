import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {Button} from "react-bootstrap";
import {vmcService} from "../Xhr/vmc.service";
import Loading from "./Loading";

interface Props {
    checked: boolean,
    top: boolean,
    onToggle: (v: boolean) => void,
    listingId: number,
}

interface State {
    loading: boolean
}

class CheckButton extends React.Component<Props, State> {
    private _isMounted = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    toggle() {
        if (this._isMounted) {
            this.setState({loading: true});
            let promise;
            if (this.props.top && this.props.checked) {
                promise = vmcService.uncheckTop(this.props.listingId)
                    .then(() => this.props.onToggle(!this.props.checked));
            }
            if (this.props.top && !this.props.checked) {
                promise = vmcService.checkTop(this.props.listingId)
                    .then(() => this.props.onToggle(!this.props.checked));
            }
            if (!this.props.top && this.props.checked) {
                promise = vmcService.uncheckBottom(this.props.listingId)
                    .then(() => this.props.onToggle(!this.props.checked));
            }
            if (!this.props.top && !this.props.checked) {
                promise = vmcService.checkBottom(this.props.listingId)
                    .then(() => this.props.onToggle(!this.props.checked));
            }
            if (promise) {
                promise.then(() => this._isMounted ? this.setState({loading: false}) : null);
            }
        }
    }

    render() {
        return (
            <Button variant={this.props.checked ? 'success' : 'outline-info'} block className={'my-3 p-3'} onClick={this.toggle} disabled={this.state.loading}>
                {this.state.loading && <Loading/>}
                {!this.state.loading &&
                <span className={'d-flex justify-content-center align-items-center'}>
                    <FontAwesomeIcon icon={this.props.checked ? faCheckCircle : faCircle} size={'lg'}/>&nbsp;
                    <span className={'h4 mb-0 ml-2'}>{this.props.checked ? 'Checked' : 'Unchecked'}</span>
                </span>
                }
            </Button>
        )
    }

}

export default CheckButton
