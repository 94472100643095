import React from "react";
import {Alert, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import AddressFinder from "./AddressFinder";
import BranchFinder from "./BranchFinder";
import Loading from "./Loading";
import {AddressItem, BranchItem} from "../vmc-types";
import {vmcService} from "../Xhr/vmc.service";

interface Props {
    disabled: boolean,
    top: boolean
    onListingAdd: () => void,
    onClose: () => void
    listingId: number,
}

interface State {
    loading: boolean,
    address: AddressItem | undefined,
    branches: BranchItem[],
    create: boolean,
    branch: BranchItem | undefined,
    sstc_day: number,
    sstc_month: number,
    sstc_year: number,
    price: number,
    error: string,
    linkId: number
}

class ListingAddForm extends React.Component<Props, State> {

    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            price: 0,
            sstc_day: 0,
            sstc_month: 0,
            sstc_year: 0,
            loading: false,
            address: undefined,
            branches: [],
            create: false,
            branch: undefined,
            error: '',
            linkId: 0
        }
        this._isMounted = false;
        this.close = this.close.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.clearAddress = this.clearAddress.bind(this);
        this.setBranch = this.setBranch.bind(this);
        this.handleBranchSelect = this.handleBranchSelect.bind(this);
        this.clearBranch = this.clearBranch.bind(this);
        this.handleSSTCDay = this.handleSSTCDay.bind(this);
        this.handleSSTCMonth = this.handleSSTCMonth.bind(this);
        this.handleSSTCYear = this.handleSSTCYear.bind(this);
        this.handleDateSubmit = this.handleDateSubmit.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.addListing = this.addListing.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    close() {
        this.props.onClose();
    }

    setAddress(address: AddressItem) {
        if (this._isMounted) {
            this.setState({address});
            this.checkForExistingListing({address: address.id});
        }
    }

    clearAddress() {
        if (this._isMounted) {
            this.setState({address: undefined, branch: undefined, branches: [], error: ''});
        }
    }

    checkForExistingListing(filters: object) {
        vmcService.listingExists(filters, ['branch'])
            .then(
                response => {
                    let branches = this.getBranches(response.data);
                    let create = response.data && response.data.data.length === 0;
                    let linkId = response.data && response.data.data.length === 1 ? response.data.data[0].id : 0;
                    if (this._isMounted) {
                        this.setState({branches, create, linkId});
                    }
                },
                error => null,
            );
    }

    getBranches(data: any) {
        return data.included ? data.included.filter((branch: any) => branch.type === 'branch') : [];
    }

    setBranch(e: any) {
        if (this._isMounted) {
            if (+e.target.value !== 0) {
                this.setState({branch: this.state.branches.find(branch => +branch.id === +e.target.value), create: false});
            } else {
                this.setState({branch: undefined, create: true});
            }
        }
    }

    handleBranchSelect(branch: BranchItem) {
        if (this._isMounted) {
            this.setState({branch});
            if (this.state.address) {
                this.checkForExistingListing({address: this.state.address.id, branch: branch.id});
            }
        }
    }

    clearBranch() {
        if (this._isMounted) {
            this.setState({branch: undefined, error: ''});
        }
    }

    handleSSTCDay(e: any) {
        if (this._isMounted) {
            this.setState({sstc_day: e.target.value});
        }
    }

    handleSSTCMonth(e: any) {
        if (this._isMounted) {
            this.setState({sstc_month: e.target.value});
        }
    }

    handleSSTCYear(e: any) {
        if (this._isMounted) {
            this.setState({sstc_year: e.target.value});
        }
    }

    handlePriceChange(e: any) {
        if (this._isMounted) {
            this.setState({price: e.target.value});
        }
    }

    handleDateSubmit(e: any) {
        e.stopPropagation();
        e.preventDefault();
    }

    addListing() {
        if (this.state.branch && this.state.address) {
            if (this._isMounted) {
                this.setState({loading: true});
            }
            let promise;
            if (this.state.create) {
                let sstc = this.state.sstc_year && this.state.sstc_month && this.state.sstc_day ? this.state.sstc_year + '-' + this.state.sstc_month + '-' + this.state.sstc_day : null;
                let milestones = sstc ? {sstc} : [];
                let data = {
                    branch_id: this.state.branch.id,
                    address_id: this.state.address.id,
                    milestones,
                    top: this.props.top,
                    price: this.state.price
                }
                if (this._isMounted) {
                    this.setState({loading: true});
                    promise = vmcService.addToChain(this.props.listingId, data);
                }
            } else {
                promise = this.props.top ? vmcService.addTop(this.props.listingId, this.state.linkId) : vmcService.addBottom(this.props.listingId, this.state.linkId);
            }
            promise && promise.then(response => {
                this.props.onListingAdd();
                if (this._isMounted) {
                    this.setState({
                        price: 0,
                        sstc_day: 0,
                        sstc_month: 0,
                        sstc_year: 0,
                        loading: false,
                        address: undefined,
                        branches: [],
                        create: false,
                        branch: undefined,
                        error: '',
                        linkId: 0
                    });
                }
            })
                .catch(error => {
                    if (this._isMounted) {
                        this.setState({
                            loading: false,
                            error: error.data.message
                        });
                    }
                })
        }
    }

    render() {
        let branchOptions = this.state.branches.map(branch => <option key={branch.id} value={branch.id}>{branch.attributes.name}</option>);
        let days = Array.from(Array(32).keys()).slice(1).map(day => <option key={day} value={day}>{day}</option>);
        let years = [new Date().getFullYear() - 1, new Date().getFullYear()].map(year => <option key={year} value={year}>{year}</option>);
        return (
            <Card className={'mb-3'}>
                <Card.Header><h3 className={'mb-0'}>Add Listing To {this.props.top ? 'Top' : 'Bottom'}</h3></Card.Header>
                <Card.Body className={'text-left'}>

                    <Alert variant={'danger'} show={this.state.error !== ''} style={{opacity: 1}}>{this.state.error}</Alert>

                    {!this.state.address &&
                    <>
                        <h4>Search for the address</h4>
                        <AddressFinder onSelect={this.setAddress}/>
                    </>
                    }

                    {this.state.address &&
                    <>
                        <p className={'mb-0 text-muted'}>Address</p>
                        <h4 className={'mb-3'}>{this.state.address.attributes.full_address}&nbsp;<Button variant={'link'} className={'small p-0'}
                                                                                                         onClick={this.clearAddress}>Change?</Button></h4>
                    </>
                    }

                    {this.state.branches.length > 0 && !this.state.branch && this.state.address && !this.state.create &&
                    <>
                        <h4>Pick a branch</h4>
                        <select onChange={this.setBranch} className={'form-control form-control-lg'} value={this.state.branch}>
                            <option>Select Branch</option>
                            {branchOptions}
                            <option value={0}>Create a new listing</option>
                        </select>
                    </>
                    }

                    {this.state.create && !this.state.branch && this.state.address &&
                    <>
                        <h4>Search for the branch</h4>
                        <BranchFinder onSelect={this.handleBranchSelect}/>
                    </>
                    }

                    {this.state.branch && this.state.address &&
                    <>
                        <p className={'mb-0 text-muted'}>Branch</p>
                        <h4>{this.state.branch.attributes.name}
                            &nbsp;<Button variant={'link'} className={'small p-0'} onClick={this.clearBranch}>Change?</Button>
                        </h4>
                    </>
                    }

                    {this.state.create && this.state.branch && this.state.address &&
                    <Form onSubmit={this.handleDateSubmit}>
                        <Row>
                            <Col>
                                <Form.Label>
                                    <h4 className={'mb-0'}>SSTC</h4>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label htmlFor="sstc_day" srOnly>
                                    Day
                                </Form.Label>
                                <select value={this.state.sstc_day} placeholder={'Day'} onChange={this.handleSSTCDay} name={'sstc_day'} className={'form-control form-control-lg'}>
                                    {days}
                                </select>
                            </Col>
                            <Col>

                                <Form.Label htmlFor="sstc_month" srOnly>
                                    Month
                                </Form.Label>
                                <select value={this.state.sstc_month} placeholder={'Month'} onChange={this.handleSSTCMonth} name={'sstc_month'}
                                        className={'form-control form-control-lg'}>
                                    <option value={1}>January</option>
                                    <option value={2}>February</option>
                                    <option value={3}>March</option>
                                    <option value={4}>April</option>
                                    <option value={5}>May</option>
                                    <option value={6}>June</option>
                                    <option value={7}>July</option>
                                    <option value={8}>August</option>
                                    <option value={9}>September</option>
                                    <option value={10}>October</option>
                                    <option value={11}>November</option>
                                    <option value={12}>December</option>
                                </select>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="sstc_year" srOnly>
                                    Year
                                </Form.Label>
                                <select value={this.state.sstc_year} placeholder={'Day'} onChange={this.handleSSTCYear} name={'sstc_year'}
                                        className={'form-control form-control-lg'}>
                                    {years}
                                </select>
                            </Col>
                        </Row>
                        <Form.Group controlId={'price'}>
                            <Form.Label><h4>Price</h4></Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>£</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    value={this.state.price}
                                    size={'lg'}
                                    placeholder={"Price"}
                                    type={'number'}
                                    inputMode={'decimal'} step={1000} min={0}
                                    pattern="\d*"
                                    onChange={this.handlePriceChange}
                                />
                            </InputGroup>

                        </Form.Group>
                    </Form>
                    }
                </Card.Body>
                <Card.Footer>
                    <button className={'btn btn-outline-primary w-25 btn-lg mx-2'} onClick={this.close}>Cancel</button>
                    <button className={'btn btn-primary w-25 btn-lg mx-2'} onClick={this.addListing} disabled={!this.state.address || !this.state.branch || this.state.loading}>
                        {this.state.loading && <Loading className={'text-light'}/>}
                        {!this.state.loading && this.state.create && 'Add new listing'}
                        {!this.state.loading && !this.state.create && 'Add existing listing'}
                    </button>
                </Card.Footer>
            </Card>
        )
    }

}

export default ListingAddForm;
