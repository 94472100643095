export function parseJwt(token: string): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function toQueryString(params: any) {
    const esc = encodeURIComponent;
    return Object.keys(params)
        .sort()
        .filter(k => params[k] !== null)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

}

