import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {toQueryString} from "../Utilities/Utils";

let baseURL = process.env.REACT_APP_VMC_URL;

const axios = Axios.create({
    baseURL: baseURL,
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
});

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (localStorage.getItem('vmc_access_token')) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('vmc_access_token');
    }
    return config;
});

axios.interceptors.response.use(response => response, error => {
    const originalRequest = error.config;
    // if the access token request failed
    if (error.response && error.response.status === 401 && originalRequest.url === baseURL + 'oauth/token') {
        localStorage.removeItem('vmc_access_token');
        return Promise.reject(error.response);
    }
    // if this is the first authentication failure grab the access token and try again
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        localStorage.removeItem('vmc_access_token');
        originalRequest._retry = true;
        return token().then(r => +r.status === 200 ? axios(originalRequest).then(response => response) : null);
    }
    return Promise.reject(error.response);
});

const token = () => {
    return Axios.create({
        baseURL: baseURL,
        headers: {
            // 'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // withCredentials: true
    })
        .post('oauth/token', {
            grant_type: 'client_credentials',
            client_id: process.env.REACT_APP_VMC_CLIENT_ID,
            client_secret: process.env.REACT_APP_VMC_CLIENT_SECRET,
            scope: '*',
        })
        .then(response => {
            localStorage.setItem('vmc_access_token', response.data.access_token);
            return response;
        });
}

const pipe = (callback: () => Promise<AxiosResponse<any>>) => localStorage.getItem('vmc_access_token') ? callback() : token().then(callback);

const milestoneGroups = () => pipe(() => axios.get('api/v1/milestone_group'));

const listing = (id: number, includes = '') => pipe(() => axios.get(`api/v1/integration/listing/${id}?includes=${includes}`));

const chainedListings = (id: number, includes = '') => pipe(() => axios.get(`api/v1/integration/listing/${id}/chained-listings?includes=${includes}`));

const groupStates = (id: number) => pipe(() => axios.get(`api/v1/integration/listing/${id}/group-state`));

const branch = (id: number, includes = '') => pipe(() => axios.get(`api/v1/integration/branch/${id}?includes=${includes}`));

const milestoneGroupWorkflow = (id: number, group: number | null = null) => pipe(() => axios.get(`api/v1/integration/workflow/${id}/${group}`));

const searchListings = (full_address: string, rows = 5) => pipe(() => axios.post(`/api/v1/integration/listing/filter?rows=${rows}`, {full_address}))
const searchAddresses = (full_address: string, rows = 5) => pipe(() => axios.post(`/api/v1/address/filter?rows=${rows}`, {full_address}))
const searchBranches = (name: string, rows = 5) => pipe(() => axios.post(`/api/v1/branch/filter?rows=${rows}`, {name}))

const chainData = (id: number) => pipe(() => axios.get(`/api/v1/integration/listing/${id}/chain/data`));
const checkTop = (id: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/check-top`));
const uncheckTop = (id: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/uncheck-top`));
const checkBottom = (id: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/check-bottom`));
const uncheckBottom = (id: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/uncheck-bottom`));
const addTop = (id: number, linkId: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/add-top/${linkId}`));
const addBottom = (id: number, linkId: number) => pipe(() => axios.put(`/api/v1/integration/listing/${id}/chain/add-bottom/${linkId}`));
const addToChain = (id: number, data: object) => pipe(() => axios.post(`/api/v1/integration/listing/${id}/chain/add`, data));
const uncouple = (data: object) => pipe(() => axios.post(`/api/v1/integration/chain/remove-link`, data))

const updateMilestone = (listingId: number, milestoneTypeId: number, completed_at: string) => pipe(() => axios.put(`/api/v1/integration/listing/${listingId}/milestone-type/${milestoneTypeId}`, {completed_at}))
const deleteMilestone = (listingId: number, milestoneId: number) => axios.delete(`/api/v1/integration/listing/${listingId}/milestones/${milestoneId}`);

const listingExists = (data: object, includes?: string[]) => pipe(() => {
    let qs = toQueryString(data);
    if (includes) {
        qs += '&includes=' + includes.join('|');
    }
    return axios.get(`/api/v1/integration/listing/exists?${qs}`);
});

export const vmcService = {
    token,
    milestoneGroups,
    listing,
    chainedListings,
    groupStates,
    branch,
    milestoneGroupWorkflow,
    searchListings,
    searchAddresses,
    searchBranches,
    chainData,
    checkTop,
    uncheckTop,
    checkBottom,
    uncheckBottom,
    addTop,
    addBottom,
    addToChain,
    uncouple,
    listingExists,
    updateMilestone,
    deleteMilestone
}
