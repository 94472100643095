import {AddressItem} from "../vmc-types";
import React from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {vmcService} from "../Xhr/vmc.service";
import {Alert, Button, Form, ListGroup} from "react-bootstrap";
import Loading from "./Loading";

interface Props {
    onSelect: (address: AddressItem) => void
}

interface State {
    loading: boolean,
    houseNumber: string,
    postcode: string,
    addresses: AddressItem[],
    error: boolean,
    showNoResults: boolean
}

const searchAPIDebounced = AwesomeDebouncePromise(vmcService.searchAddresses, 500);

class AddressFinder extends React.Component<Props, State> {
    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            houseNumber: '',
            postcode: '',
            addresses: [],
            error: false,
            showNoResults: false
        }
        this._isMounted = false;
        this.handleHouseNumberChange = this.handleHouseNumberChange.bind(this);
        this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleHouseNumberChange(e: any) {
        if (this._isMounted) {
            this.setState({houseNumber: e.target.value});
        }
    }

    handlePostcodeChange(e: any) {
        if (this._isMounted) {
            this.setState({postcode: e.target.value});
        }
    }

    onSubmit(e: any) {
        e.stopPropagation();
        e.preventDefault();
        if (this._isMounted) {
            this.setState({loading: true});
        }
        let term = this.state.houseNumber + ' ' + this.state.postcode;
        this.search(term);
    }

    async search(term: string) {
        try {
            const result = await searchAPIDebounced(term);
            if (result.data) {
                if (result.data.data) {
                    if (this._isMounted) {
                        this.setState({
                            addresses: result.data.data,
                            loading: false,
                            error: false,
                            showNoResults: result.data.data.length === 0
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        addresses: [],
                        loading: false,
                        error: false,
                        showNoResults: true
                    });
                }
            }
        } catch (e) {
            if (this._isMounted) {
                this.setState({loading: false, error: true})
            }
        }
    }

    render() {
        const results = this.state.addresses.map(address => <ListGroup.Item key={address.id} action
                                                                            onClick={() => this.props.onSelect(address)}>{address.attributes.full_address}</ListGroup.Item>)
        return (
            <>
                <div className="row justify-content-center sticky-top">
                    <div className="col-md-12">
                        <Form inline onSubmit={this.onSubmit}>
                            <Form.Label htmlFor="houseNumber" srOnly>
                                House Number
                            </Form.Label>
                            <Form.Control
                                size={'lg'}
                                className="mr-2"
                                id="houseNumber"
                                placeholder="House Number"
                                onChange={this.handleHouseNumberChange}
                            />
                            <Form.Label htmlFor="postcode" srOnly>
                                Postcode
                            </Form.Label>
                            <Form.Control id="postcode" placeholder="Postcode" onChange={this.handlePostcodeChange} className={'mr-2'} size={'lg'}/>
                            <Button type="submit" className="" disabled={this.state.loading} size={'lg'}>
                                {this.state.loading ? <Loading className={'text-light'}/> : 'Submit'}
                            </Button>
                        </Form>
                    </div>
                </div>
                {results.length > 0 &&
                <ListGroup className={'my-2'}>
                    {results}
                </ListGroup>
                }
                <Alert variant={'info'} className={'mt-3'} show={this.state.showNoResults} style={{opacity: 1}}>Sorry, we couldn't find any addresses that
                    match, please try again.</Alert>
            </>
        );
    }
}

export default AddressFinder;
