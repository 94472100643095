import React from "react";
import {BranchItem} from "../vmc-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {vmcService} from "../Xhr/vmc.service";
import {Alert, Button, Form, ListGroup} from "react-bootstrap";
import Loading from "./Loading";

interface Props {
    onSelect: (branch: BranchItem) => void
}

interface State {
    term: string,
    loading: boolean,
    error: boolean,
    results: BranchItem[],
    showNoResults: boolean
}

const searchAPIDebounced = AwesomeDebouncePromise(vmcService.searchBranches, 500);

class BranchFinder extends React.Component<Props, State> {

    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            term: '',
            loading: false,
            error: false,
            results: [],
            showNoResults: false
        }
        this._isMounted = false;
        this.handleTermChange = this.handleTermChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleTermChange(e: any) {
        if (this._isMounted) {
            this.setState({term: e.target.value});
        }
    }

    onSubmit(e: any) {
        e.stopPropagation();
        e.preventDefault();
        if (this._isMounted) {
            this.setState({loading: true});
        }
        this.search(this.state.term);
    }

    async search(term: string) {
        try {
            const result = await searchAPIDebounced(term);
            if (result.data) {
                if (result.data.data) {
                    if (this._isMounted) {
                        this.setState({
                            results: result.data.data,
                            loading: false,
                            error: false,
                            showNoResults: result.data.data.length === 0
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        results: [],
                        loading: false,
                        error: false,
                        showNoResults: true
                    });
                }
            }
        } catch (e) {
            if (this._isMounted) {
                this.setState({loading: false, error: true})
            }
        }
    }

    render() {
        const results = this.state.results.map(branch => <ListGroup.Item key={branch.id} action
                                                                         onClick={() => this.props.onSelect(branch)}>{branch.attributes.name}</ListGroup.Item>)

        return (<>
            <div className="row justify-content-center sticky-top">
                <div className="col-md-12">
                    <Form inline onSubmit={this.onSubmit}>
                        <Form.Label htmlFor="name" srOnly>
                            Name
                        </Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2"
                            id="name"
                            placeholder="Branch Name"
                            size={'lg'}
                            onChange={this.handleTermChange}
                        />
                        <Button type="submit" className="mb-2" disabled={this.state.loading} size={'lg'}>
                            {this.state.loading ? <Loading className={'text-light'}/> : 'Submit'}
                        </Button>
                    </Form>
                </div>
            </div>
            {results.length > 0 &&
            <ListGroup className={'my-2'}>
                {results}
            </ListGroup>
            }
            <Alert variant={'info'} style={{opacity: 1}} show={this.state.showNoResults} className={'mt-3'}>Sorry, we couldn't find any branches that match, please try
                again.</Alert>
        </>)
    }
}

export default BranchFinder;
